import React, { useContext, useEffect, useState, useRef } from 'react';

import {
    IonPage,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonButton,
    useIonToast,
    useIonLoading,
    IonAlert,
    IonFooter,
    IonToolbar
} from '@ionic/react';


import './ViewPaused.scoped.scss';

import tableone from '../../Assets/tableOutline-white.png'

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewPaused = () => {
    return (
        <IonPage fullscreen>
            <IonContent fullscreen={true} style={{ textAlign: 'center',}}>
                <IonGrid style={{ maxWidth: '100rem',  }}>
                    {/* <IonRow style={{ marginTop: '1rem' }}>
                        <IonCol style={{ textAlign: 'center' }}>
                            <img src={tableone} style={{ maxHeight: '6rem' }} />
                        </IonCol>
                    </IonRow> */}
                    <IonRow className='center-row' style={{marginTop:'1rem'}}>
                    <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span
                                style={{
                                    fontFamily: "'Reem Kufi', sans-serif",
                                    letterSpacing: '0.5rem',
                                    fontSize: '18px',
                                    // width: '100%',
                                }}
                            >
                                TABLEONE<br />
                                <span style={{
                                    fontFamily: "'Reem Kufi', sans-serif",

                                    color: "#F2C498"
                                }}>SERVICE ANNOUNCEMENT</span>.
                            </span>
                        </IonCol>
                        <IonCol size={2}>
                        <img src={tableone} style={{ maxHeight: '6rem' }} className='spin animated'/>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem' }}>
                        <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '14px' }}>
                                Hi everyone,<br /><br />

                                We're currently working through some big changes to launch the next version of TableOne and will be <span style={{ color: "#F2C498" }}>pausing the service temporarily</span>.<br/><br/> 
                                We apologize for the inconvenience, but hope to be back with you all soon and <span style={{ color: "#F2C498" }}>thank you</span> for all your support!
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem', marginBottom: '10rem' }} className='center-row'>
                    <IonCol size={0.5} />
                        <IonCol style={{textAlign: 'left'}}>
                            <span style={{ fontSize: '14px' }}>
                                Frank & Tarek
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>

                </IonGrid>

            </IonContent>

            {/* <IonFooter className="ion-no-border" style={{ position: 'fixed', bottom: '2vh', textAlign: 'center' }}>
                    <IonToolbar style={{ textAlign: 'right', '--background': 'transparent' }}>
                        <IonButton
                            style={{
                                '--background': '#FFFFFF',
                                '--background-activated': '#FFFFFF',
                                '--border-radius': '32px',
                                marginRight: '2vh'
                            }}
                            onClick={() => (setShowModal(true))}
                        >
                            <span style={{ fontSize: '12px', color: '#000000' }}>
                                Claim your offer
                            </span>
                        </IonButton>
                    </IonToolbar>
                </IonFooter> */}
        </IonPage>
    )

}

export { ViewPaused };
