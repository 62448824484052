import { firebaseApp } from "../index";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

// https://firebase.google.com/docs/auth/web/phone-auth
async function doCreateUserWithPhone(phoneNumber) {
    console.log('auth: ', auth)

    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
            console.log('captcha', response)

            // async function sendCode(response) {
            //     await signInWithPhoneNumber(auth, phoneNumber, response)
            //         .then((confirmationResult) => {
            //             // SMS sent. Prompt user to type the code from the message, then sign the
            //             // user in with confirmationResult.confirm(code).
            //             window.confirmationResult = confirmationResult;
            //             // ...
            //         }).catch((error) => {
            //             // Error; SMS not sent
            //             // ...
            //             console.log('send error!', error)
            //         });
            // }

            // sendCode(response)
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
        },
        'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            console.log('expired captcha!')
          }
    });

    const appVerifier = window.recaptchaVerifier;

    await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            // ...
        }).catch((error) => {
            // Error; SMS not sent
            // ...
            console.log('send error!', error)
        });
}

// async function doCreateUserWithEmailAndPassword(email, password, name, phoneCredential) {
//     firebase.auth().useDeviceLanguage();

//     await firebase.auth().createUserWithEmailAndPassword(email, password);
//     let currentUser = firebase.auth().currentUser;
//     // Store displayName and profile picture in photoUrl (empty on initial create);
//     // await currentUser.updateProfile({ displayName: displayName, photoURL: ''});
//     await firebase.auth().updateCurrentUser(currentUser);
//     await firebase.auth().currentUser.updateProfile({ displayName: name });

//     // await firebase.auth().currentUser.updatePhoneNumber(phoneCredential)

//     await firebase.auth().currentUser.sendEmailVerification();
//     return currentUser;
// }

// async function doChangeName(name) {
//     await firebase.auth().currentUser.updateProfile({ displayName: name });
// }

// async function doChangeProfilePic(photoURL) {
//     await firebase.auth().currentUser.updateProfile({ photoURL: photoURL });
// }

// async function doDeleteProfilePic() {
//     await firebase.auth().currentUser.updateProfile({ photoURL: '' });
// }

// async function doChangePassword(email, oldPassword, newPassword) {
//     const credential = firebase.auth.EmailAuthProvider.credential(
//         email,
//         oldPassword
//     );
//     await firebase.auth().currentUser.reauthenticateWithCredential(credential);
//     await firebase.auth().currentUser.updatePassword(newPassword);
//     await doSignOut();
// }

// async function doSignInWithEmailAndPassword(email, password) {
//     await firebase.auth().signInWithEmailAndPassword(email, password);
//     if (!firebase.auth().currentUser.emailVerified) {
//         await firebase.auth().currentUser.sendEmailVerification();
//     }
// }

// async function doSendConfirmationEmail() {
//     if (!firebase.auth().currentUser.emailVerified) {
//         await firebase.auth().currentUser.sendEmailVerification();
//     }
// }

// // We only allow signup/sign with email or Google
// async function doGoogleSignIn() {
//     await firebase.auth().signInWithPopup('google');
// }

// async function doPasswordReset(email) {
//     await firebase.auth().sendPasswordResetEmail(email);
// }

// async function doPasswordUpdate(password) {
//     await firebase.auth().updatePassword(password);
// }

// async function doSignOut() {
//     await firebase.auth().signOut();
// }


export {
    doCreateUserWithPhone,
    // doCreateUserWithEmailAndPassword,
    // doGoogleSignIn,
    // doSignInWithEmailAndPassword,
    // doSendConfirmationEmail,
    // doPasswordReset,
    // doPasswordUpdate,
    // doSignOut,
    // doChangeName,
    // doChangeProfilePic,
    // doDeleteProfilePic,
    // doChangePassword
};