import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import CacheBuster from 'react-cache-buster';
import pack from '../package.json';

// import "@fontsource/poppins"
// import "@fontsource/poppins/variable.css"; // Contains ONLY variable weights and no other axes.

import { setupIonicReact } from '@ionic/react';
// https://capacitorjs.com/docs/web/pwa-elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import reportWebVitals from './reportWebVitals';

// import { AuthProvider } from './Providers/Auth';
// import { LoadingProvider } from './Providers/Loading';
// import { UIProvider } from '@den/common/src/Providers/UI';

// https://swiperjs.com/get-started
// import entire bundle
// import 'swiper/css/bundle';
// import '@ionic/react/css/ionic-swiper.css';

import './index.scss';

setupIonicReact();


ReactDOM.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={pack.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <App />
    </CacheBuster>
  </React.StrictMode>,
  document.getElementById('root')
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
