import React, { useContext, useEffect, useState, PureComponent } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	useIonAlert,
	useIonLoading,
	useIonToast,
	IonCheckbox
} from '@ionic/react';

import { useHistory } from 'react-router-dom'

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, PieChart, Pie, Bar, BarChart } from 'recharts';

import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';

import newyorker from '../../Assets/news-logos/newyorker-stack.png'
import gothamist from '../../Assets/news-logos/gothamist.png'
import forbes from '../../Assets//news-logos/forbes.png'
import expedite from '../../Assets/news-logos/expedite-white.png'

import tableone from '../../Assets/tableone-gold.png'

import { deviceType } from 'react-device-detect';

import './ViewData.scoped.scss';

import { restaurantMap } from './partners'

// https://recharts.org/en-US/examples/CustomizedLabelLineChart
class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, stroke, value, } = this.props;

		return (
			<text x={x} y={y} dy={-10} fill={stroke} fontSize={10} color={stroke} style={{ fill: '#f6f1ef' }} textAnchor="middle">
				{value}%
			</text>
		);
	}
}

function randomIntFromInterval(min, max) { // min and max included 
	return Math.floor(Math.random() * (max - min + 1) + min);
}

function wordFreq(words) {
	return words.map((word) => ({ text: word, value: randomIntFromInterval(10, 20) }));
}

const words = wordFreq([
	"Graduation", "Birthday", "Parents",
	"Friends", "Engagement", "Anniversary",
	"Promotion", "Date", "Vacation",
	"Holiday", "Bachelorette"]);

const fontScale = scaleLog({
	domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
	range: [15, 25],
});

const fontSizeSetter = (datum) => fontScale(datum.value);

const colors = ['#F2C498', '#F6F1EF', '#F5E2D2', '#F3D3B5'];

// USED TO POSITION CLOUD
const fixedValueGenerator = () => 0.5;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill={index == 1 ? '#120E2D' : "white"} fontSize={10} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewData = () => {
	const history = useHistory();

	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [code, setCode] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [restaurant, setRestaurant] = useState(false);
	const [joined, setJoined] = useState(false)

	const data02 = [
		{ name: 'most', value: 0.64, fill: '#30267B' },
		{ name: 'twice', value: 4.6, fill: '#F2C498' },
		{ name: 'once', value: 4.76, fill: '#120E2D' },
	];

	const [dayChartData, setDayChartData] = useState([
		{ x: 'm', y: 14 },
		{ x: 't', y: 14 },
		{ x: 'w', y: 14 },
		{ x: 'th', y: 14 },
		{ x: 'f', y: 15 },
		{ x: 's', y: 15 },
		{ x: 'sn', y: 14 },
	]);

	const [partyChartData, setPartyChartData] = useState([
		{ x: 1, y: 5 },
		{ x: 2, y: 41 },
		{ x: 3, y: 10 },
		{ x: 4, y: 19 },
		{ x: 5, y: 7 },
		{ x: 6, y: 7 },
		{ x: 7, y: 5 },
		{ x: 8, y: 6 },
	]);

	const [ageData, setAgeData] = useState([
		{
			"x": '22-25',
			"y": 17
		},
		{
			"x": '25-30',
			"y": 35
		},
		{
			"x": '30-35',
			"y": 30
		},
		{
			"x": '40-50',
			"y": 12
		},
		{
			"x": '50-65',
			"y": 8
		},
		{
			"x": '65+',
			"y": 4
		},
	])
	const [partyYMax, setPartyYMax] = useState(100)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [present, dismiss] = useIonLoading();
	const [partnerId, setPartnerId] = useState('123abc')

	async function handleCheck() {
		if (!isChecked) {
			setIsChecked(!isChecked)
		}

		// if (!isChecked) {
		// 	await present({
		// 		message: 'Redirecting to contact form...',
		// 		duration: 1500,
		// 		position: 'middle',
		// 		buttons: ['OK']
		// 	});

		// 	setTimeout(async () => {
		// 		window.open(`https://app.formbricks.com/s/clx97o3830keywnr9mih6m8qe?partner=${partnerId}`, '_blank');
		// 		await dismiss()
		// 	}, 1000);
		// }
	}

	async function toHome() {
		history.push(`/`);
	}

	async function toArticle(url) {
		window.open(url, '_blank');
	}

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	useEffect(() => {
		let tmpMax = 0;

		for (var partySize in partyChartData) {
			if (partyChartData[partySize] > tmpMax) {
				tmpMax = 1.1 * partyChartData[partySize]
			}
		}

		setPartyYMax(tmpMax)
	}, [partyChartData])

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('code');

			// if (tmpReferrer?.length == 36) {
			setCode(tmpReferrer);
			// }

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	useEffect(() => {
		if (code in restaurantMap) {
			setRestaurant(restaurantMap[code]['name']);
			setJoined(restaurantMap[code]['joined'])
		}
	}, [code])

	return (
		<IonPage fullscreen>
			<IonModal
				isOpen={isChecked}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				style={{ '--height': '400px', '--background': 'white' }}
				onDidDismiss={() => (setIsChecked(false))}
			>
				<IonContent style={{ '--background': 'white' }}>
					<div style={{ position: 'relative', height: '400px', overflow: 'auto' }}>
						<iframe
							src={`https://app.formbricks.com/s/clx97o3830keywnr9mih6m8qe?code=${code}&restaurant=${restaurant}`}
							frameborder="0"
							style={{
								position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', border: 0
							}}
						>
						</iframe>
					</div>
				</IonContent>
			</IonModal>

			<IonHeader style={{ 'background': '#000000' }}>
				<IonGrid style={{ '--background': '#000000', margin: 0, padding: 0 }}>
					<IonRow>
						{/* <IonCol size={1}>
						<img src={tableOutline} className='spin animated' style={{maxHeight: '5rem'}}/>
						</IonCol> */}
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }} onClick={toHome}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>


					<hr style={{
						marginTop: deviceType === "mobile" ? '1rem' : '2rem',
						marginBottom: '0rem',
						color: '#7a7a7a62',
						backgroundColor: '#7a7a7a62',
						width: '85%'
					}} />
				</IonGrid>
			</IonHeader>


			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true} style={{ textAlign: 'center', }}>
				<IonGrid
					style={{
						padding: '0rem',
						height: '100%',
						maxWidth: '50rem'
					}}
				>
					{(!loadingQueryParams && !restaurant) &&
						<>
							<IonRow style={{ marginTop: '2rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										About Us
									</span>
								</IonCol>
							</IonRow>
						</>
					}

					{(!loadingQueryParams && restaurant) && <>

						<IonRow style={{ marginTop: '2rem' }}>
							<IonCol>
								<span style={{
									fontFamily: "'Nunito Sans', sans-serif",
									fontSize: '1.4rem',
									color: '#F6F1EF'
								}}>
									<span style={{ color: "#F2C498" }}>{restaurant}</span> has been on TableOne since
								</span>
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>
								<span style={{
									fontFamily: "'Nunito Sans', sans-serif",
									fontSize: '1.4rem',
									color: '#F6F1EF'
								}}>
									<b>{joined}</b>
								</span>
							</IonCol>
						</IonRow>

					</>}

					<IonRow>
						<IonCol size={0.5} />
						{/* <IonCol style={{ textAlign: 'left' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								For too long, the NYC reservation scene has been plagued by bots and resellers.
								These bad actors have profited off of the demand that NYC restaurants have
								worked so hard to garner, while offering nothing in return except no shows and impersonators. <br /><br />

								In response, TableOne was built by a community of passionate foodies.
								Over the past year, we've worked with existing reservation platforms
								to connect some of NYC's most iconic restaurants with over 30,000 real
								and enthusiastic diners. <br /><br />

								New York is the very first state to create a bill aimed to combat reservation resellers
								and we couldn't be more excited about it! As a valued part of our community, we'd be
								honored to receive your consent to keep your restaurant on TableOne. <br /><br />

								We've put together some information below to show you what we bring to the table.
								Whatever the decision, we've loved having you on TableOne and look forward to
								hearing from you.<br /><br />

								Frank & Tarek <br />
								Co-Founders, TableOne
							</p>
						</IonCol> */}

						<IonCol style={{ textAlign: 'left' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								NYC restaurants have been plagued by bots and resellers,
								who offer nothing but no shows, impersonators, and huge fees. In response, we built TableOne. <br /><br />

								For the past year, we've been connecting over <b>50,000 high-quality diners</b> to the world’s most iconic restaurants through existing reservation platforms like Resy and OpenTable. 
								
								<br/><br/><span style={{ color: "#F2C498" }}>{restaurant} </span> has been one of those restaurants and we'd like to invite you to remain on TableOne.<br /><br />
								{/* 
								New York is the first state to create a bill aimed to combat reservation 
								resellers and we couldn't be more excited about it! <br /><br /> */}

								Being on TableOne requires no additional cost or work on your end. <b>All we need is a yes or no!</b><br /><br />

								Below is some information that shows what we've brought to the table. We look forward to hearing from you.<br /><br />

								{/* Whatever your decision is, we've loved having — on TableOne and 
								look forward to hearing from you.<br /><br /> */}

								{/* Frank & Tarek <br />
								Co-Founders, TableOne */}
							</p>
						</IonCol>


						<IonCol size={0.5} />

					</IonRow>


					<IonRow style={{marginTop: '-3rem'}}>
						<IonCol size={1.5} />

						<IonCol style={{ textAlign: 'center' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								Frank Besson & Tarek Arafat<br />
								Co-Founders, TableOne
							</p>
						</IonCol>


						<IonCol size={1.5} />

					</IonRow>

					{(!loadingQueryParams && restaurant) && <IonRow onClick={handleCheck}>
						<IonCol>
							{/* <IonCol size={2} style={{ textAlign: 'right' }}>
							<IonCheckbox
								checked={isChecked}
							// disabled={isChecked}
							/>
						</IonCol> */}

							<IonButton style={{
								fontSize: '1.2rem', fontWeight: 700,
								height: '4.4rem',
								width: '12rem',
								'--padding-start': 0,
								'--padding-end': 0,
								'--border-radius': '36px',
								'--background': '#F2C498',
								'--background-activated': '#F2C498',
								'--background-hover': '#F2C498',
								'--color': '#000000',
								textAlign: 'center',
								fontWeight: 600,
							}}
								onClick={handleCheck}
							>
								<span style={{
									fontFamily: "'Nunito Sans', sans-serif",
									fontSize: '1rem',
								}}>
									Click to Respond
								</span>
							</IonButton>
						</IonCol>
					</IonRow>}

					{/* <IonRow>
						<IonCol>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.4rem',
								color: '#F2C498'
							}}>
								Read About TableOne
							</span>
						</IonCol>
					</IonRow> */}

					<IonRow style={{ 'height': '7.5rem' }} className='center-row'>
						<IonCol sizeXs={0} sizeSm={0} sizeMd={0} />
						<IonCol>
							<img src={forbes} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.forbes.com/sites/lauriewerner/2024/04/27/mothers-day-gift-guide-2024-top-new-york-restaurant-gift-certificates'))} />
						</IonCol>
						<IonCol>
							<img src={expedite} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.expedite.news/p/battle-of-the-reservations-bots'))} />
						</IonCol>
						<IonCol>
							<img src={gothamist} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://gothamist.com/arts-entertainment/dinner-reservations-at-tatiana-are-impossible-to-get-so-i-spent-a-month-trying'))} />
						</IonCol>
						<IonCol>
							<img src={newyorker} className='center-image' style={{ cursor: 'pointer', maxWidth: deviceType === 'mobile' ? '7rem ' : '11rem' }} onClick={() => (toArticle('https://www.newyorker.com/news/our-local-correspondents/why-you-cant-get-a-restaurant-reservation'))} />
						</IonCol>

						<IonCol sizeXs={0} sizeSm={0} sizeMd={0} />
					</IonRow>

					<hr style={{
						marginTop: '2rem',
						marginBottom: '2rem',
						color: '#7a7a7a62',
						backgroundColor: '#7a7a7a62',
						width: '85%'
					}} />

					<IonRow style={{ marginBottom: '2rem', }}>
						<IonCol>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.4rem',
								color: '#F2C498'
							}}>
								About the Restaurants
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={9} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Cost to the Restaurants</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Trick question, we're 100% free!

									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>Free</b>
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={7} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Clicks</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Average per restaurant.

									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>10,652 <span style={{ fontSize: '1rem' }}>/ mo</span></b>
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={9} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Reservations</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Average per restaurant.
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>219 <span style={{ fontSize: '1rem' }}>/ mo</span></b>
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={7} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Revenue</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Average per restaurant.
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>$49,275 <span style={{ fontSize: '1rem' }}>/ mo</span></b>
							</span>
						</IonCol>
					</IonRow>

					<hr style={{
						marginTop: '3rem',
						marginBottom: '2rem',
						color: '#7a7a7a62',
						backgroundColor: '#7a7a7a62',
						width: '85%'
					}} />

					<IonRow style={{ marginBottom: '2rem', }}>
						<IonCol>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.4rem',
								color: '#F2C498'
							}}>
								About the Diners
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={9} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Cost to the Diners</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Another trick question, also free.
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>Free</b>
							</span>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', height: '6rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol size={8} style={{ height: '100%', margin: 0, padding: 0 }}>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Total Diners</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										How many diners use TableOne?
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
						<IonCol style={{ height: '100%', margin: 0, padding: '1.8rem 2.2rem 0px 0px', textAlign: 'right' }}>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.6rem',
								color: '#F6F1EF'
							}}>
								<b>50,174</b>
							</span>
						</IonCol>
					</IonRow>
					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Diner Types</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										How often does our audience eat out?
									</span>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonRow style={{ marginLeft: '2rem', marginTop: '2rem' }}>
										<IonCol size={2} style={{ textAlign: 'center' }}>
											<span style={{
												marginTop: '6px',
												height: '15px',
												width: '15px',
												backgroundColor: '#30267B',
												borderRadius: '50%',
												display: 'inline-block'
											}} />
										</IonCol>
										<IonCol style={{ textAlign: 'left' }}>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1rem',
												color: '#F6F1EF'
											}}>
												Most nights
											</span>
										</IonCol>
									</IonRow>
									<IonRow style={{ marginLeft: '2rem', marginTop: '0rem' }}>
										<IonCol size={2} style={{ textAlign: 'center' }}>
											<span style={{
												marginTop: '6px',
												height: '15px',
												width: '15px',
												backgroundColor: '#F2C498',
												borderRadius: '50%',
												display: 'inline-block'
											}} />
										</IonCol>
										<IonCol style={{ textAlign: 'left' }}>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1rem',
												color: '#F6F1EF'
											}}>
												A couple of times per week
											</span>
										</IonCol>
									</IonRow>
									<IonRow style={{ marginLeft: '2rem', marginTop: '0rem' }}>
										<IonCol size={2} style={{ textAlign: 'center' }}>
											<span style={{
												marginTop: '6px',
												height: '15px',
												width: '15px',
												backgroundColor: '#120E2D',
												borderRadius: '50%',
												display: 'inline-block'
											}} />
										</IonCol>
										<IonCol style={{ textAlign: 'left' }}>
											<span style={{
												fontFamily: "'Nunito Sans', sans-serif",
												fontSize: '1rem',
												color: '#F6F1EF'
											}}>
												At least once per week
											</span>
										</IonCol>
									</IonRow>
								</IonCol>
								<IonCol style={{ textAlign: 'center', marginLeft: '0rem', padding: '0rem 0rem 0rem 0rem', }}>
									<div style={{
										width: '100%',
										height: '18vh',
										backgroundColor: "transparent",
										padding: '0rem',

									}}>
										<ResponsiveContainer width="100%" height="100%" >
											{/* https://recharts.org/en-US/examples/PieChartWithCustomizedLabel */}
											<PieChart width={200} height={250}>
												<Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={30} outerRadius={60} label={renderCustomizedLabel} labelLine={false} />
											</PieChart>
										</ResponsiveContainer>
									</div>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Gender</b>
									</span>
								</IonCol>
							</IonRow>
							{/* <IonRow>
							<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
								<span style={{
									fontFamily: "'Nunito Sans', sans-serif",
									fontSize: '1rem',
									color: '#F6F1EF'
								}}>
									A bit about who's dining with you
								</span>
							</IonCol>
						</IonRow> */}

							<IonRow style={{ marginTop: '1rem', height: '1.6rem' }}>
								<IonCol size={1} />
								<IonCol style={{ background: '#120E2D', height: '100%' }} size={4.4} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										44%
									</span>
								</IonCol>
								<IonCol style={{ background: '#F2C498', height: '100%' }} size={4.2} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#120E2D'
									}}>
										42%
									</span>
								</IonCol>
								<IonCol style={{ background: '#30267B', height: '100%' }} size={1.4} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										14%
									</span>
								</IonCol>
								<IonCol size={1} />
							</IonRow>

							<IonRow style={{ marginTop: '-0.5rem' }}>
								<IonCol size={1} />
								<IonCol size={4.4}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Male
									</span>
								</IonCol>
								<IonCol size={4.2}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Female
									</span>
								</IonCol>
								<IonCol size={1.4} >
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										N/A
									</span>
								</IonCol>
								<IonCol size={1} />
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Diner Ages</b>
									</span>
								</IonCol>
							</IonRow>
							{/* <IonRow>
							<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
								<span style={{
									fontFamily: "'Nunito Sans', sans-serif",
									fontSize: '1rem',
									color: '#F6F1EF'
								}}>
									How old is the audience you're attracting?
								</span>
							</IonCol>
						</IonRow> */}

							<IonRow>
								<IonCol style={{ textAlign: 'center', marginLeft: '0rem', padding: '0rem 0rem 0rem 0rem', }}>
									<div style={{
										width: '100%',
										height: '20vh',
										backgroundColor: "transparent",
										padding: '1rem',

									}}>
										<ResponsiveContainer width="100%" height="100%"  >

											<BarChart data={ageData}>
												<Bar dataKey="y" fill="#F2C498" />
												<XAxis
													dataKey="x"
													axisLine={false}
													interval={0}
													tickSize={0}
													// angle={45}

													tick={{ fill: '#f6f1ef', fontSize: '10px', }}
													padding={{ bottom: 1 }}
													dy={8}
												/>
											</BarChart>

											{/* <LineChart
												data={dayChartData}
												margin={{
													top: 20,
													right: 30,
													left: 30,
												}}
												title='AVAILABIITY FOUND'
											>
												<Line type="monotone" dataKey="y" stroke="#f2c498" fill="#f2c498" label={<CustomizedLabel />} style={{ marginBottom: '100px' }} />
												<XAxis
													dataKey="x"
													axisLine={false}
													interval={0}
													tickSize={0}
													tick={{ fill: '#f6f1ef', fontSize: '10px', padding: '100px' }}
												/>
												<YAxis domain={[0, partyYMax]} hide={true} />
											</LineChart> */}

										</ResponsiveContainer>
									</div>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '-3rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Years Old
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Diner Occasions</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										What our diners are celebrating.
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '0.5rem' }}>
								<IonCol className='center-row'>
									{/* https://airbnb.io/visx/wordcloud */}
									<div className="wordcloud">
										<Wordcloud
											words={words}
											width={325}
											height={125}
											fontSize={fontSizeSetter}
											font={'Impact'}
											padding={1}
											spiral={'archimedean'}
											rotate={0}
											random={fixedValueGenerator}
										>
											{(cloudWords) =>
												cloudWords.map((w, i) => {
													return <Text
														key={w.text}
														fill={colors[i % colors.length]}
														textAnchor={'middle'}
														transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
														fontSize={w.size}
														fontFamily={w.font}
													>
														{w.text}
													</Text>
												})
											}
										</Wordcloud>
									</div>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Requested Party Size(s)</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										How many people are our users bringing to the table?
									</span>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol style={{ textAlign: 'center', marginLeft: '0rem', padding: '0rem 0rem 0rem 0rem', }}>
									<div style={{
										width: '100%',
										height: '20vh',
										backgroundColor: "transparent",
										padding: '1rem',

									}}>
										<ResponsiveContainer width="100%" height="100%"  >

											<LineChart
												data={partyChartData}
												margin={{
													top: 20,
													right: 30,
													left: 30,
													// bottom: 20,
												}}
												title='AVAILABIITY FOUND'
											>
												<Line type="monotone" dataKey="y" stroke="#f2c498" fill="#f2c498" label={<CustomizedLabel />} style={{ marginBottom: '100px' }} />
												{/* <CartesianGrid stroke="#ccc" /> */}
												<XAxis
													dataKey="x"
													axisLine={false}
													interval={0}
													tickSize={0}
													tick={{ fill: '#f6f1ef', fontSize: '10px', padding: '100px' }}
												// label={{ value: 'Pages', position: 'center', offset: 10 }}
												/>
												<YAxis domain={[0, partyYMax]} hide={true} />
											</LineChart>

										</ResponsiveContainer>
									</div>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '-3rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Party Size
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>


					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Requested Day of the Week(s)</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										What days do our users like to dine?
									</span>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol style={{ textAlign: 'center', marginLeft: '0rem', padding: '0rem 0rem 0rem 0rem', }}>
									<div style={{
										width: '100%',
										height: '20vh',
										backgroundColor: "transparent",
										padding: '1rem',

									}}>
										<ResponsiveContainer width="100%" height="100%"  >

											<LineChart
												data={dayChartData}
												margin={{
													top: 20,
													right: 30,
													left: 30,
													// bottom: 20,
												}}
												title='AVAILABIITY FOUND'
											>
												<Line type="monotone" dataKey="y" stroke="#f2c498" fill="#f2c498" label={<CustomizedLabel />} style={{ marginBottom: '100px' }} />
												{/* <CartesianGrid stroke="#ccc" /> */}
												<XAxis
													dataKey="x"
													axisLine={false}
													interval={0}
													tickSize={0}
													tick={{ fill: '#f6f1ef', fontSize: '10px', padding: '100px' }}
												// label={{ value: 'Pages', position: 'center', offset: 10 }}
												/>
												<YAxis domain={[0, 25]} hide={true} />
											</LineChart>

										</ResponsiveContainer>
									</div>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '-3rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										Day of the Week
									</span>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow style={{ background: '#232323', marginLeft: '2rem', marginRight: '2rem', marginBottom: '1rem', borderRadius: '2rem' }}>
						<IonCol>
							<IonRow style={{ marginTop: '8px' }}>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1.4rem',
										color: '#F6F1EF'
									}}>
										<b>Requested Reservation Timeframe(s)</b>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol style={{ textAlign: 'left', padding: '0px 0px 0px 2rem', margin: 0 }}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										What times do our users like to dine?
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '1rem', height: '1.6rem' }}>
								<IonCol size={1} />
								<IonCol style={{ background: '#120E2D', height: '100%' }} size={2.2} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										22%
									</span>
								</IonCol>
								<IonCol style={{ background: '#F2C498', height: '100%' }} size={6.2} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#120E2D'
									}}>
										62%
									</span>
								</IonCol>
								<IonCol style={{ background: '#30267B', height: '100%' }} size={1.6} className='center-row'>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										16%
									</span>
								</IonCol>
								<IonCol size={1} />

							</IonRow>

							<IonRow style={{ marginTop: '-0.5rem' }}>
								<IonCol size={1} />
								<IonCol size={2.2}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										4pm - 6pm
									</span>
								</IonCol>
								<IonCol size={6.2}>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										6pm - 9pm
									</span>
								</IonCol>
								<IonCol size={1.6} >
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#F6F1EF'
									}}>
										9pm+
									</span>
								</IonCol>
								<IonCol size={1} />
							</IonRow>

						</IonCol>
					</IonRow>

					<IonRow style={{ height: '2.5rem' }}>

					</IonRow>

				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewData };
