import React, { useState, useEffect } from 'react';

import {
	realm,
	doLogin,
	doLoginAnon,
	doInsertReferral,
	doInsertCheckout,
} from '../Helpers/Mongo'

import {
	IonAlert,
    useIonToast,
    useIonLoading,
} from '@ionic/react';

const DataContext = React.createContext();

const DataProvider = ({ children }) => {
	const [user, setUser] = useState("");
	const [shareUser, setShareUser] = useState(false);

    const [showSubModal, setShowSubModal] = useState(false)

    const [presentAlert] = useIonToast();
    const [present, dismiss] = useIonLoading();

	async function login(user) {
		await doLogin(user)
		
	}

	async function handleReferral(referrer, email) {
		await doInsertReferral(referrer, email)
	}

	async function handleReferral(referrer, email) {
		await doInsertReferral(referrer, email)
	}

    async function handleCheckout(deviceId) {
        await doInsertCheckout(deviceId)
    }

	useEffect(() => {
		// if (queryParameters.get('user')) {
		// 	setShareUser(queryParameters.get('user'))
		// }
		async function init() {
			await doLoginAnon();
			// await handleLeaderboard()
		}

		init()
	}, [])

	// useEffect(() => {
    //     if (phone && !showSubModal) {
    //         handlePhone()
    //     }
    // }, [phone, showSubModal])

	// useEffect(() => {
	// 	if (shareUser) {
	// 		findBracket(shareUser)
	// 	}
	// }, [shareUser])

	return (
		<DataContext.Provider value={{ 
			login,
			user, 
			shareUser, 
			setShareUser, 
			handleReferral,
            handleCheckout,
			setShowSubModal,
		}}>
			{children}
		</DataContext.Provider>
	);

}

export {
	DataContext,
	DataProvider
};