export const restaurantMap = {
	"2b13f692-3e21-4713-beb1-56369f382734": {
	  "name": "Laser Wolf",
	  "joined": "March 24, 2023"
	},
	"6f6c88e5-0287-4fcc-8b6f-0db9d2d7d8bb": {
	  "name": "Monkey Bar",
	  "joined": "March 24, 2023"
	},
	"ef0f11c0-513c-4268-8b7b-fd0465588a05": {
	  "name": "Employees Only",
	  "joined": "April 7, 2023"
	},
	"7069a794-86d4-4ebc-9795-0ddbf4b738bb": {
	  "name": "Raoul's",
	  "joined": "March 24, 2023"
	},
	"ac668f23-a641-4216-9d7b-08616a23e060": {
	  "name": "Lilia",
	  "joined": "March 24, 2023"
	},
	"ccde0e54-86de-4e3a-837a-f38cba9d1ac3": {
	  "name": "Don Angie",
	  "joined": "March 23, 2023"
	},
	"cfbac5c1-ca15-4598-9754-3daf84594a84": {
	  "name": "I Sodi",
	  "joined": "March 24, 2023"
	},
	"1c5da836-9393-465b-aaef-3859dff8002e": {
	  "name": "Sushi Nakazawa",
	  "joined": "March 23, 2023"
	},
	"c9ba2873-9293-4f05-8b20-5c15b74bf8ee": {
	  "name": "Dhamaka",
	  "joined": "March 23, 2023"
	},
	"19040573-16b1-4302-9834-836d903214a8": {
	  "name": "Raf's",
	  "joined": "July 10, 2023"
	},
	"771c4df9-ba5d-449e-81a0-969efd6590d3": {
	  "name": "Carbone",
	  "joined": "March 23, 2023"
	},
	"7b51c451-f909-49bf-a8da-90b0beaddde1": {
	  "name": "Claud",
	  "joined": "March 24, 2023"
	},
	"114177e7-cefe-40c7-a662-8b8429639632": {
	  "name": "Dame",
	  "joined": "April 7, 2023"
	},
	"122637b9-8c2a-4b13-a07e-6c09ecd6835d": {
	  "name": "Dante",
	  "joined": "April 7, 2023"
	},
	"3376dd18-3e3a-4490-8ad8-2c37796b9163": {
	  "name": "Bangkok Supper Club",
	  "joined": "October 12, 2023"
	},
	"a0cc40bf-dbc1-47e3-8c91-fad8a5934f20": {
	  "name": "Sailor",
	  "joined": "November 17, 2023"
	},
	"0cdb47fe-02b4-4c23-a81f-5052f5767f9b": {
	  "name": "Four Horsemen",
	  "joined": "November 17, 2023"
	},
	"e05064ef-f079-4015-8514-6dfa1004603b": {
	  "name": "Gramercy Tavern",
	  "joined": "January 26, 2024"
	},
	"79471d17-26b9-4cb1-8635-5c5c982c0b45": {
	  "name": "L\u2019Artusi",
	  "joined": "April 7, 2023"
	},
	"422aa099-a46b-4d94-8b9a-93fcffb268ee": {
	  "name": "Gage & Tollner",
	  "joined": "April 7, 2023"
	},
	"a292f2b8-309d-4780-9ca2-92b91bb5371b": {
	  "name": "Konban",
	  "joined": "January 26, 2024"
	},
	"eb951b18-c827-44bc-9b00-7c26e6571dfe": {
	  "name": "Forsythia",
	  "joined": "January 26, 2024"
	},
	"6a0fa1f7-9580-4955-b4b6-c9a79cc64961": {
	  "name": "B'artusi",
	  "joined": "April 7, 2023"
	},
	"03fdd85e-abe3-479e-9900-8ce1c40d6020": {
	  "name": "Double Chicken Please",
	  "joined": "April 7, 2023"
	},
	"1afa7bbb-4302-4bf7-a6c3-f7e2ddf10b0e": {
	  "name": "COQODAQ",
	  "joined": "January 26, 2024"
	},
	"eb0e184e-5d04-44ee-aa08-4fbf50a9779b": {
	  "name": "Tonchin",
	  "joined": "January 26, 2024"
	},
	"053b6d8c-d012-472b-94f1-e635e893ddac": {
	  "name": "Cafe Carmellini",
	  "joined": "January 26, 2024"
	},
	"942911df-2c71-4e1c-9437-036adc6a0950": {
	  "name": "Misi",
	  "joined": "March 24, 2023"
	},
	"6ba259f2-92bc-44fb-927a-0ec29032c7a0": {
	  "name": "Tatiana",
	  "joined": "April 7, 2023"
	},
	"5d56c6b4-0f12-478f-a8c6-e5070cd62347": {
	  "name": "Foul Witch",
	  "joined": "September 7, 2023"
	},
	"a7c78e70-bb28-4029-8cd7-3448210dc7db": {
	  "name": "4 Charles",
	  "joined": "March 24, 2023"
	},
	"4f3605bd-57a6-4e53-900f-a64feb6e63bd": {
	  "name": "Torrisi",
	  "joined": "March 24, 2023"
	},
	"859445e6-0ba0-4b6f-980d-59d575078508": {
	  "name": "Via Carota",
	  "joined": "July 10, 2023"
	},
	"79109ef2-56dc-45b0-8bd9-4725a1e7e6fc": {
	  "name": "Fish Cheeks",
	  "joined": "January 26, 2024"
	},
	"463cd75d-015d-495c-93f9-405a319cb3d7": {
	  "name": "Buvette",
	  "joined": "June 3, 2023"
	},
	"9c3ccc36-9488-4e2e-badf-b4ca9f81abb7": {
	  "name": "N\u014dksu",
	  "joined": "November 17, 2023"
	},
	"135f8957-f25a-47b2-bed1-972b4734aa14": {
	  "name": "Blue Box Caf\u00e9",
	  "joined": "November 21, 2023"
	},
	"b38ff69c-1830-435b-8181-14e1a903c6b5": {
	  "name": "Rolo's",
	  "joined": "January 26, 2024"
	},
	"7d58228d-f431-48a5-afd2-fd1b5960eca5": {
	  "name": "J\u014dji",
	  "joined": "January 26, 2024"
	},
	"1ff4ca00-4e20-4815-befb-20bcbddb616c": {
	  "name": "Le Bernardin",
	  "joined": "July 21, 2023"
	},
	"98451dde-4869-404d-bb01-2aa6c2889d3e": {
	  "name": "Tokyo Record Bar",
	  "joined": "September 18, 2023"
	},
	"31b1b574-8185-4ff7-a4cf-fa43baa0fe22": {
	  "name": "Roscioli - Tasting Menu",
	  "joined": "September 18, 2023"
	},
	"b920d139-4877-4c8a-9453-5d4c38598c0d": {
	  "name": "Manhatta",
	  "joined": "January 26, 2024"
	},
	"a778d757-3857-4075-b268-63cbb71243b7": {
	  "name": "Peter Luger",
	  "joined": "January 26, 2024"
	},
	"314a4e15-0ff0-417c-91b4-92f9fc0a97c0": {
	  "name": "Ci Siamo",
	  "joined": "January 26, 2024"
	},
	"a81cfbdf-38fc-426f-b0c5-e7e0b1682b62": {
	  "name": "Rezd\u00f4ra",
	  "joined": "March 24, 2023"
	},
	"ce9b00b6-a1e0-4b00-a449-fb16c2e1b2e9": {
	  "name": "Rubirosa",
	  "joined": "April 7, 2023"
	},
	"f7e7b0b0-d7cb-490c-afa7-e9cd8a833e8a": {
	  "name": "Libertine",
	  "joined": "July 10, 2023"
	},
	"16a78bc0-cd8e-4f62-a717-841c78784c9a": {
	  "name": "Oxomoco",
	  "joined": "July 10, 2023"
	},
	"444f30d3-c4ba-47f1-a96a-f66acb28346d": {
	  "name": "Jeju",
	  "joined": "July 21, 2023"
	},
	"9e1f3725-27ab-4166-a2d0-aac3b786ad7e": {
	  "name": "Au Cheval",
	  "joined": "June 8, 2023"
	},
	"b5864680-d4b3-4cbc-a515-b6260448f13c": {
	  "name": "Una Pizza Napoletana",
	  "joined": "July 21, 2023"
	},
	"c0d0897c-769c-4232-8e7b-6dfbf2e12372": {
	  "name": "ILIS",
	  "joined": "November 17, 2023"
	},
	"265063fa-952f-4393-8cb1-541320b365bf": {
	  "name": "Le B.",
	  "joined": "January 26, 2024"
	},
	"f249039d-b5db-4207-957e-16a67e9cf5f3": {
	  "name": "Sixty Three Clinton",
	  "joined": "September 7, 2023"
	},
	"a2d99c58-af3a-4946-83b7-58940d8da23a": {
	  "name": "Minetta Tavern",
	  "joined": "January 26, 2024"
	},
	"8246fd30-eef3-4780-8a10-4c4fe3da4214": {
	  "name": "Potluck Club",
	  "joined": "January 26, 2024"
	},
	"775b5418-d32a-462d-996b-29a4819fd331": {
	  "name": "Semma",
	  "joined": "May 11, 2023"
	},
	"78cb5292-2475-414d-9f61-906d12ec5a92": {
	  "name": "Jua",
	  "joined": "January 26, 2024"
	},
	"78db3983-3a22-4103-be4d-8c5eea43981c": {
	  "name": "Sartiano's",
	  "joined": "September 7, 2023"
	},
	"21f6ead0-32e1-4d7f-b60d-3b7dd60c87fe": {
	  "name": "COTE",
	  "joined": "October 12, 2023"
	},
	"11ff4c6b-227e-4e35-bb10-dc9f7e76254b": {
	  "name": "Atoboy",
	  "joined": "January 26, 2024"
	},
	"82a958a4-ba41-449d-b743-aa315e2afa6e": {
	  "name": "Soothr",
	  "joined": "January 26, 2024"
	},
	"de6685a5-3417-4675-b45f-6d7ea37865a3": {
	  "name": "Overstory",
	  "joined": "January 26, 2024"
	},
	"bf9baf0b-88e9-423f-9970-abb87f465243": {
	  "name": "Thai Diner",
	  "joined": "January 26, 2024"
	},
	"350f99b5-3331-4e22-a1a5-e62764da0b5d": {
	  "name": "Foxface Natural",
	  "joined": "January 30, 2024"
	},
	"4d398a10-8141-4783-938d-f35a070a1564": {
	  "name": "Roscioli - A la Carte",
	  "joined": "February 12, 2024"
	},
	"3d1a06d6-aced-4a6f-9719-c049c7ae8511": {
	  "name": "Sip & Guzzle",
	  "joined": "March 8, 2024"
	},
	"5161237b-284e-4404-88b8-8038cbbdd405": {
	  "name": "San Sabino",
	  "joined": "March 8, 2024"
	},
	"9018bf2d-d418-4703-b583-a93de21c5a53": {
	  "name": "Eyval",
	  "joined": "March 8, 2024"
	},
	"22bd9c36-2ec8-4096-8cc1-0871f94c6c4d": {
	  "name": "Penny",
	  "joined": "April 28, 2024"
	},
	"15bb27b6-f206-491d-aa5f-bf6c1b75dcbd": {
	  "name": "Bungalow",
	  "joined": "April 28, 2024"
	},
	"4a94058a-21f4-4b20-a490-797970d9d5d1": {
	  "name": "Theodora",
	  "joined": "April 28, 2024"
	},
	"2034296d-af10-4e68-b099-5c3b0dd7b446": {
	  "name": "Cervo's",
	  "joined": "April 28, 2024"
	},
	"d04bc697-a4f3-4765-8528-089c4f527109": {
	  "name": "Sofreh",
	  "joined": "April 28, 2024"
	},
	"17d01346-308f-4bc4-8209-b956ace6c342": {
	  "name": "Blanca",
	  "joined": "April 28, 2024"
	},
	"1d73adb2-a781-446e-b448-9aadec53a54c": {
	  "name": "Shukette",
	  "joined": "April 28, 2024"
	},
	"436af0db-1933-4ce9-a6a9-a5193cf9d10d": {
	  "name": "Fradei",
	  "joined": "April 28, 2024"
	},
	"62ffb21d-3490-4783-834b-abd7eab0373a": {
	  "name": "Tolo",
	  "joined": "April 28, 2024"
	},
	"fc32463e-cd9e-4a19-8977-82ec3942769b": {
	  "name": "Da Toscano",
	  "joined": "April 28, 2024"
	},
	"bfc6abe9-099d-4ca8-b6c2-fb6c6775c1c0": {
	  "name": "COTE",
	  "joined": "October 12, 2023"
	}
  }