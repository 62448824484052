import {
    IonPage,
    IonCol,
    IonContent,
    IonHeader,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonButton,
    IonModal
} from '@ionic/react';

import '../ViewPrivacy.scoped.scss';

const titleText = {
    color: '#F2C498',
    fontSize: '22px',
    fontWeight: 600,
    width: '100%',
}

const titleRow = {
    marginTop: '22px',
    marginBottom: '8px',
}

const PrivacyTitle = ({children}) => {
    return (
        <IonRow style={titleRow}>
            <IonCol>
                <span
                    style={titleText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { PrivacyTitle };