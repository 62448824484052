import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	useIonAlert,
	useIonLoading,
	useIonToast
} from '@ionic/react';

import tableone from '../../Assets/tableone-notable-blue.png'
import party from '../../Assets/party.png'
import classyparty from '../../Assets/classyparty.png'
import rolls from '../../Assets/rolls.png'

// import { BsChatFill } from "react-icons/bs";
import { TbCopy } from "react-icons/tb";


import { DataContext } from '../../Providers/DataProvider';

import './ViewReferral.scoped.scss';

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewReferral = () => {
	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [referrer, setReferrer] = useState(false);

	const {
		handleReferral
	} = useContext(DataContext)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [present, dismiss] = useIonToast();

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('deviceId');

			if (tmpReferrer?.length == 36) {
				setReferrer(tmpReferrer);
			}

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	async function toShare() {
		const shareLink = `https://tableone.app?referrer=${referrer}`
		// console.log(shareLink)

		// const os = getMobileOperatingSystem()
		navigator.clipboard.writeText(shareLink);

		present({
			message: 'Shareable link copied',
			duration: 1000,
			position: 'middle',
			className: "custom-toast"
		});


		// window.open(`sms:open?;&body=Hey! Join tableone using this link...\n${shareLink}`, '_blank');
	};

	return (
		<IonPage fullscreen>
			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true} style={{ textAlign: 'center', }}>

				<IonGrid
					style={{
						padding: '0rem',
						height: '100%'
					}}
				>
					<IonRow style={{height: '10%', 'background-color': '#FED6DF',}}>
						{/* <IonCol size={1}>
						<img src={tableOutline} className='spin animated' style={{maxHeight: '5rem'}}/>
						</IonCol> */}
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>

					<IonRow style={{
						paddingTop: '30%',
						height: '60%',
						'background-color': '#FED6DF',
					}}>
						<IonCol size={12} style={{ textAlign: 'center', paddingLeft: '16px' }}>

							<IonRow>
								<IonCol>
									<span style={{
										fontFamily: "'Reem Kufi', sans-serif",
										fontSize: '1.6rem',
										color: '#000000',
										wordSpacing: '0.2rem',
										letterSpacing: '0.2rem'
									}}>
										GET FIRST DIBS.
									</span>
								</IonCol>
							</IonRow>

							<IonRow style={{ marginTop: '1rem' }}>
								<IonCol>
									<span style={{
										fontFamily: "'Nunito Sans', sans-serif",
										fontSize: '1rem',
										color: '#000000'
									}}>
										Refer <b>3 friends</b> and unlock Priority Alerts.
									</span>
								</IonCol>
							</IonRow>

							{(!loadingQueryParams && referrer) &&
								<IonRow style={{ marginTop: '1rem' }}>
									<IonCol>
										<IonButton
											onClick={() => (toShare())}
											// shape="round"
											style={{
												fontSize: '1.2rem', fontWeight: 700,
												height: '4.4rem',
												width: '17rem',
												'--padding-start': 0,
												'--padding-end': 0,
												'--border-radius': '36px',
												'--background': '#000000',
												'--background-activated': '#000000',
												'--background-hover': '#000000',
												'--color': '#F6F1EF',
												textAlign: 'center',
												fontWeight: 600,

											}}
										>
											<TbCopy size={15} />
											<span style={{ marginTop: '2px', marginLeft: '6px' }}>Copy your referral link</span>
										</IonButton>
									</IonCol>
								</IonRow>
							}
						</IonCol>
					</IonRow>

					<IonRow style={{
						height: '15%',
						background: '#000000'
					}}>
						<IonCol style={{ textAlign: 'center' }} className='center-row'>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '2rem',
								color: '#F6F1EF'
							}}>
								How it works
							</span>
						</IonCol>
					</IonRow>
					<IonRow style={{
						padding: 0,
						// maxWidth: '100rem',
						height: '15%',
						background: '#000000'
					}}>
						<IonCol style={{
							padding: 0,
							margin: '0rem',
							'background-image': `url(${party})`,
							'background-size': 'cover',
							'background-repeat': 'no-repeat',
							'background-attachment': 'fixed',
							'background-position': '80% 50%',
							textAlign: 'center'
						}} className='center-row'>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								<span style={{ color: "#F2C498" }}>Share</span> your link <br />with friends.
							</span>
						</IonCol>
						<IonCol style={{
							padding: 0,
							margin: '0rem',
							'background-image': `url(${classyparty})`,
							'background-size': 'cover',
							'background-repeat': 'no-repeat',
							'background-attachment': 'fixed',
							'background-position': '80% 50%'
						}} className='center-row'>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								They <span style={{ color: "#F2C498" }}>signup</span> and<br /><span style={{ color: "#F2C498" }}>download</span> the app.
							</span>
						</IonCol>
						<IonCol style={{
							padding: 0,
							margin: '0rem',
							'background-image': `url(${rolls})`,
							'background-size': 'cover',
							'background-repeat': 'no-repeat',
							'background-attachment': 'fixed',
							'background-position': '80% 50%'
						}} className='center-row'>
							<span style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								After <span style={{ color: "#F2C498" }}>3 referrals</span>,<br />unlock Priority Alerts.
							</span>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewReferral };
