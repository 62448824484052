import {
    IonPage,
    IonCol,
    IonContent,
    IonHeader,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonButton,
    IonModal
} from '@ionic/react';

import '../ViewTerms.scoped.scss';

const sectionText = {
    color: '#F2C498',
    fontSize: '26px',
    fontWeight: 600,
    width: '100%',
}

const sectionRow = {
    marginTop: '26px',
    marginBottom: '8px',
}

const PrivacySection = ({ children }) => {
    return (
        <IonRow style={sectionRow}>
            <IonCol>
                <span
                    style={sectionText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { PrivacySection };