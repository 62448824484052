import React, { useContext, useEffect, useState, useRef } from 'react';

import {
    IonPage,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
} from '@ionic/react';

import { useHistory } from 'react-router-dom'

import { DataContext } from '../../Providers/DataProvider';

import './ViewOfferSuccess.scoped.scss';

import tableone from '../../Assets/tableOutline-white.png'

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewOfferSuccess = () => {
    const {
        handleCheckout
    } = useContext(DataContext);

	const queryParameters = new URLSearchParams(window.location.search)

    const history = useHistory();

	async function toHome() {
		history.push(`/`);
	}

    useEffect(()=>{
        async function handle() {
            const deviceId = queryParameters.get('deviceId')

            if (deviceId) {
                if (deviceId.length == 36) {
                    await handleCheckout(deviceId)
                }
            }
        }

        handle()
    },[queryParameters])

    return (
        <IonPage fullscreen>
            <IonContent fullscreen={true} style={{ textAlign: 'center',}}>
                <IonGrid style={{ maxWidth: '100rem',  }}>
                    {/* <IonRow style={{ marginTop: '1rem' }}>
                        <IonCol style={{ textAlign: 'center' }}>
                            <img src={tableone} style={{ maxHeight: '6rem' }} />
                        </IonCol>
                    </IonRow> */}
                    <IonRow className='center-row' style={{marginTop:'1rem'}}>
                    <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span
                                style={{
                                    fontFamily: "'Reem Kufi', sans-serif",
                                    letterSpacing: '0.5rem',
                                    fontSize: '18px',
                                    // width: '100%',
                                }}
                            >
                                THANK <span style={{
                                    fontFamily: "'Reem Kufi', sans-serif",

                                    color: "#F2C498"
                                }}>YOU</span><br />
                               FOR SUPPORTING TABLEONE.
                            </span>
                        </IonCol>
                        <IonCol size={2}>
                        <img src={tableone} style={{ maxHeight: '6rem' }} className='spin animated'/>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem' }}>
                        <IonCol size={0.5} />
                        <IonCol style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '14px' }}>
                                Without you, we wouldn't be able to continue building TableOne. We've got <span style={{ color: "#F2C498" }}>big plans</span>, and hope you enjoy being a member.<br /><br />
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>
                    <IonRow style={{ marginTop: '2rem', marginBottom: '10rem' }} className='center-row'>
                    <IonCol size={0.5} />
                        <IonCol style={{textAlign: 'left'}}>
                            <span style={{ fontSize: '14px' }}>
                                Frank & Tarek
                            </span>
                        </IonCol>
                        <IonCol size={0.5} />
                    </IonRow>

                </IonGrid>

            </IonContent>

            {/* <IonFooter className="ion-no-border" style={{ position: 'fixed', bottom: '2vh', textAlign: 'center' }}>
                    <IonToolbar style={{ textAlign: 'right', '--background': 'transparent' }}>
                        <IonButton
                            style={{
                                '--background': '#FFFFFF',
                                '--background-activated': '#FFFFFF',
                                '--border-radius': '32px',
                                marginRight: '2vh'
                            }}
                            onClick={() => (setShowModal(true))}
                        >
                            <span style={{ fontSize: '12px', color: '#000000' }}>
                                Claim your offer
                            </span>
                        </IonButton>
                    </IonToolbar>
                </IonFooter> */}
        </IonPage>
    )

}

export { ViewOfferSuccess };
