// FIREBASE VERSION 10

import { initializeApp } from 'firebase/app';
// import { initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: String(process.env.REACT_APP_FIREBASE_API_KEY),
    authDomain: String(process.env.REACT_APP_FIREBASE_DOMAIN),
    projectId: String(process.env.REACT_APP_FIREBASE_PROJECT_ID),
    storageBucket: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    messagingSenderId: String(process.env.REACT_APP_FIREBASE_SENDER_ID),
    appId: String(process.env.REACT_APP_FIREBASE_APP_ID),
    measurementId: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID)
};

const firebase = initializeApp(firebaseConfig);

// RECAPTCHA ENTERPRISE
// https://firebase.google.com/docs/app-check/web/recaptcha-enterprise-provider

// let reCaptchaProvider;
// if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev') {
//     reCaptchaProvider = new ReCaptchaEnterpriseProvider(String(process.env.REACT_APP_FIREBASE_APPCHECK_TOKEN));
// } else {
//     reCaptchaProvider = new ReCaptchaEnterpriseProvider(String(process.env.REACT_APP_FIREBASE_APPCHECK_TOKEN));
//     // captchaKey = new ReCaptchaV3Provider(String(process.env.REACT_APP_FIREBASE_APPCHECK_V3_TOKEN));
// }
// console.log('captcha key:', captchaKey)

// https://firebase.google.com/docs/app-check/web/recaptcha-enterprise-provider#initialize
// const appCheck = initializeAppCheck(firebase, {
//     provider: reCaptchaProvider,
//     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

export const firebaseApp = firebase;