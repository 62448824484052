import {
    IonPage,
    IonCol,
    IonContent,
    IonHeader,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonButton,
    IonModal
} from '@ionic/react';

import '../ViewTerms.scoped.scss';

const paragraphText = {
    color: 'white',
    fontSize: '18px',
    fontWeight: 400,
    width: '100%',
}

const paragraphRow = {
    marginTop: '8px',
    marginBottom: '8px',
}


const PrivacyParagraph = ({ children }) => {
    return (
        <IonRow style={paragraphRow}>
            <IonCol>
                <span
                    style={paragraphText}
                >
                    {children}
                </span>
            </IonCol>
        </IonRow>
    )
}

export { PrivacyParagraph };