import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	IonPage,
	IonCol,
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonModal,
	IonFooter,
	IonToolbar,
	IonButtons,
	IonHeader,
	useIonAlert,
	useIonLoading,
	IonInput
} from '@ionic/react';


import { doCreateUserWithPhone } from '../../Helpers/Firebase/Auth';

import { DataContext } from '../../Providers/DataProvider';
import { deviceType } from 'react-device-detect';

import { IoIosArrowForward } from "react-icons/io";


import tableone from '../../Assets/tableone-gold.png'

import './ViewAuth.scoped.scss';

// https://stackoverflow.com/questions/59426050/how-to-stop-re-render-of-component-on-route-changeusually-on-going-back-to-hist
const ViewAuth = () => {
	const queryParameters = new URLSearchParams(window.location.search)

	const [loadingQueryParams, setLoadingQueryParams] = useState(true);
	const [referrer, setReferrer] = useState(false);

	const {
		handleReferral
	} = useContext(DataContext)

	const [localEmail, setLocalEmail] = useState('')

	// const [presentAlert, dismiss] = useIonToast();
	const [presentAlert] = useIonAlert();
	const [present, dismiss] = useIonLoading();

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	async function handleSubmit() {
		const queryParameters = new URLSearchParams(window.location.search)


		const referrer = queryParameters.get('referrer')
		console.log(localEmail, referrer.length)

		if (!validateEmail(localEmail) || referrer.length !== 36) {
			presentAlert({
				message: 'Valid email & referrer Required',
				duration: 1500,
				position: 'middle',
				cssClass: 'custom-alert',
				buttons: ['OK']
			});
			return;
		} else {
			await present('Saving referral')

			await handleReferral(
				referrer,
				localEmail
			)

			await dismiss()

			window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_self');
		}

	}

	useEffect(() => {
		async function handle() {
			const tmpReferrer = queryParameters.get('referrer');

			if (tmpReferrer?.length == 36) {
				setReferrer(tmpReferrer);
			}

			setLoadingQueryParams(false);
		}

		handle();
	}, [queryParameters])

	function toArticle(url) {
		window.open(url, '_blank');
	}

	async function toDownload() {
		// const os = getMobileOperatingSystem()

		window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');

		// if (os === "iOS") {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// } 
		// else if (os === "Android") {
		// 	window.open('https://play.google.com/store/apps/details?id=com.fyi.hooli.app', '_blank');
		// } 
		// else {
		// 	window.open('https://apps.apple.com/us/app/tableone-nyc/id6448799631', '_blank');
		// }
	};

	return (
		<IonPage fullscreen>
			<div id="recaptcha-container" size={{height: '50vh', width: '100vw'}}/>

			<IonHeader style={{ '--background': 'transparent' }}>
				<IonGrid style={{ '--background': 'transparent' }}>
					<IonRow>
						{/* <IonCol size={1}>
						<img src={tableOutline} className='spin animated' style={{maxHeight: '5rem'}}/>
						</IonCol> */}
						<IonCol style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '1rem' }}>
							<img src={tableone} style={{ height: '4rem' }} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonHeader>


			<IonFooter style={{ '--background': 'transparent' }} class="ion-no-border">
				<IonGrid>
					<IonRow style={{ 'marginTop': '0rem', }}>
						<IonCol size={12} style={{ textAlign: 'center' }}>
							<p style={{
								fontFamily: "'Nunito Sans', sans-serif",
								fontSize: '1.2rem',
								color: '#F6F1EF'
							}}>
								TableOne Solutions Inc. © 2024
							</p>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonFooter>

			{/* <ModalSignup show={showSignup} setShow={setShowSignup} closeable={true} /> */}
			<IonContent fullscreen={true} style={{ textAlign: 'center', }}>

				<IonGrid
					style={{
						// background: '',
						padding: '0rem',
						// width: '100vw',
						maxWidth: '100rem'
					}}
				>
					<IonRow>
						<IonCol>
							<IonButton onClick={()=>(doCreateUserWithPhone("+19084214801"))}> 
								try
							</IonButton>
						</IonCol>
					</IonRow>

				</IonGrid>
			</IonContent >
		</IonPage >
	)

}

export { ViewAuth };
